import React, { useContext, useEffect } from 'react'
import Layout from '../layout'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import renderBloks from '@renderBloks'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ACC_THEME from '../../themes'
import { H3, MarkdownHandler } from '@system'
import get from 'lodash/get'
import PageContext from '@context'

const useStyles = makeStyles({
  mainBody: {
    margin: '0 auto',
    marginTop: ({ title, description, pageContainerHeight }) =>
      title || description
        ? 'inherit'
        : pageContainerHeight === 'high'
        ? '-280px'
        : pageContainerHeight === 'low'
        ? '-80px'
        : '-140px',
    position: 'relative',
    zIndex: '2',
    '&>div:first-child': {
      background: ACC_THEME.palette.common.white,
      minHeight: (props) => (props.doesSpacingComponentExist ? '0vh' : '15vh'),
      color: ACC_THEME.palette.primary.main,
    },
  },
  outerContainer: {
    margin: '0 auto',
    alignItems: 'center',
    textAlign: 'center',
    padding: (props) => (props.title ? '45px 20px' : '1px'),
    position: 'relative',
    maxWidth: '900px',
    zIndex: '2',
  },
  innerContainer: {
    maxHeight: '315px',
    overflow: 'hidden',
    color: ACC_THEME.palette.text.white,
  },
  headerTitle: {
    color: ACC_THEME.palette.common.white,
  },
  descriptionContainer: {
    paddingTop: '20px',
  },
  markdown: {
    marginBottom: '0px',
    color: ACC_THEME.palette.text.white,
  },
})

const DetailsPage = (props) => {
  const {
    headerImage,
    body,
    title,
    description,
    canonicalPage,
    noIndex,
    _uid,
    conversionType,
    metaData,
  } = props.blok

  const doesSpacingComponentExist = get(body, '[0].component') === 'spacing'
  const classes = useStyles({ ...props.blok, doesSpacingComponentExist })
  const isInEditor = useContext(PageContext).isInEditor
  const context = useContext(PageContext)
  const succesConversionType =
    !!context.location &&
    context.location.state &&
    context.location.state.conversionType
  useEffect(() => {
    if (succesConversionType && !isInEditor) {
      window.mutiny = window.mutiny || []
      window.mutiny?.client?.trackConversion({ name: 'details-page' })
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'page.content',
        'page.content': {
          v: 11,
          type: 'paid conversion',
          convType: succesConversionType || conversionType || 'generic',
          id: _uid,
          name: metaData.title,
          url: document.URL,
        },
      })
    }
  }, [])
  return (
    <Layout
      metaData={props.blok.metaData}
      canonicalPage={canonicalPage}
      noIndex={noIndex}
    >
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <Box>
          {!!headerImage &&
            headerImage.length > 0 &&
            renderBloks(headerImage, {
              includesInPageOffset: title || description,
            })}
          <Box className={classes.outerContainer}>
            <Box className={classes.innerContainer}>
              {!!title && (
                <H3 className={classes.headerTitle} component="h1">
                  {title}
                </H3>
              )}
              {!!description && (
                <Box className={classes.descriptionContainer}>
                  <MarkdownHandler className={classes.markdown}>
                    {description}
                  </MarkdownHandler>
                </Box>
              )}
            </Box>
          </Box>
          {!!body && (
            <Box className={classes.mainBody}>{renderBloks(body)}</Box>
          )}
        </Box>
      </ThemeProvider>
    </Layout>
  )
}

export default DetailsPage
